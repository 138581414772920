/**
 * Init a global object for passing data from PHP to JS
 *
 * Mostly used for targeting Google Tag Manager or Google Optimize
 *
 * @see ot_asunnot_global_object
 *
 * Append variabled in PHP like this
 * wp_add_inline_script('otas', 'window.otAsunnot.myVar = true;');
 *
 * @type {*|{}}
 */
window.otAsunnot = window.otAsunnot || {};
